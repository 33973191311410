import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItem = () => {
  const location = useLocation();

  const MenuProducts = [
    { name: "Veg Appetizers", href: "/dishes/vegAppetizers" },
    { name: "Non-Veg Appetizers", href: "/dishes/nonvegAppetizers" },
    { name: "Chat Corner", href: "/dishes/chatCorner" },
    { name: "Main-Course Veg", href: "/dishes/mainCourseVeg" },
    { name: "Main-Course Non-Veg", href: "/dishes/mainCourseNonVeg" },
    { name: "Pakwan-Special", href: "/dishes/pakwanSpecial" },
    { name: "Lunch-Special", href: "/dishes/lunchSpecial" },
    { name: "Rice And Biryani", href: "/dishes/riceAndBiryani" },
    { name: "Indo Chinese", href: "/dishes/indoChinese" },
    { name: "Naans And Breads", href: "/dishes/naansAndBreads" },
    { name: "Sides", href: "/sides" },
    { name: "Desserts", href: "/desserts" },
    { name: "Drinks", href: "/drinks" },
    { name: "Kidz Menu", href: "/kidzMenu" },
  ];

  return (
    <div className="text-white mt-[100px] ml-[20px] fontStyling flex flex-col p-4 border-r-2">
      {MenuProducts.map(menuProduct => (
        <Link to={menuProduct.href} key={menuProduct.href}>
          <button
            className={`button ${
              location.pathname === menuProduct.href ? "active" : ""
            }`}
          >
            {menuProduct.name}
          </button>
        </Link>
      ))}
    </div>
  );
};

export default MenuItem;
