import React from "react";
import { Link } from "react-router-dom";

export const FeaturedProducts = ({ dishes }) => {
  return (
    <>
      <div className="bg-white">
        {" "}
        {/* Changed bg-black to bg-white for the background */}
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="text-[25px] lg:text-[50px] text-black tracking-tight fontStyling heading-styling opacity-80">
            {/* Changed text color to text-black for visibility */}
            Picked for you
          </h2>
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {dishes.map(product => (
              <Link to={`/dishDetail/${product.id}`}>
                <div key={product.id} className="group relative">
                  <div className="opacity-80 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-60 lg:h-80">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm text-black">
                        {" "}
                        {/* Changed text color to text-black for visibility */}
                        <a href={product.href}>
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          {product.name}
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
