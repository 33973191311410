import React from "react";
import Layout from "../layout/Layout";
import { useParams } from "react-router-dom";

const DishDetail = ({ dishes }) => {
  const { id } = useParams();
  const selectedDish = dishes.find(dish => dish.id === parseInt(id, 10));

  if (!selectedDish) {
    // Handle case when the dish is not found
    return <p className="text-black">Dish not found</p>; // Ensure text is black for visibility
  }

  const { name, imageSrc, imageAlt, doordash, uber } = selectedDish;

  return (
    <Layout>
      <div className="container mx-auto py-[100px] bg-white">
        {" "}
        {/* Ensure background is white */}
        <div className="flex flex-col items-center">
          <img
            src={imageSrc}
            alt={imageAlt}
            className="w-full md:w-2/3 lg:w-1/2 rounded-lg shadow-md mb-8"
          />
          <p className="text-2xl font-bold mb-[40px] text-black">{name}</p>{" "}
          {/* Ensure text is black for visibility */}
          <div className="flex space-x-4">
            <a
              href={uber}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-yellow-500 text-black px-6 py-2 rounded-md font-semibold hover:bg-yellow-400 hover:text-white transition duration-300 border border-yellow-500" // Adjusted for better visibility
            >
              Uber Eats
            </a>
            <div style={{ width: "16px" }}></div>
            <a
              href={doordash}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 text-black px-6 py-2 rounded-md font-semibold hover:bg-red-400 hover:text-white transition duration-300 border border-red-500" // Adjusted for better visibility
            >
              DoorDash
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DishDetail;
