import React from "react";
import Layout from "./Layout";
import MenuItem from "../components/MenuItem";

const FoodMenuLayout = ({ children }) => {
  return (
    <Layout>
      <div className="flex">
        <div className="lg:p-5">
          <MenuItem />
        </div>
        <div className="w-[100%] ">{children}</div>
      </div>
    </Layout>
  );
};

export default FoodMenuLayout;
