import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FoodMenu from "./pages/FoodMenu";
import About from "./pages/About";
import VegAppetizers from "./components/FoodMenu/VegAppetizers";
import NonVegAppetizers from "./components/FoodMenu/NonVegAppetizers";
import ChatCorner from "./components/FoodMenu/ChatCorner";
import MainCourseVeg from "./components/FoodMenu/MainCourseVeg";
import MainCourseNonVeg from "./components/FoodMenu/MainCourseNonVeg";
import PakwanSpecial from "./components/FoodMenu/PakwanSpecial";
import LunchSpecial from "./components/FoodMenu/LunchSpecial";
import RiceAndBiryani from "./components/FoodMenu/RiceAndBiryani";
import IndoChinese from "./components/FoodMenu/IndoChinese";
import NaansAndBreads from "./components/FoodMenu/NaansAndBreads";
import Sides from "./components/FoodMenu/Sides";
import Dessert from "./components/FoodMenu/Dessert";
import Drinks from "./components/FoodMenu/Drinks";
import KidzMenu from "./components/FoodMenu/KidzMenu";

import DishDetail from "./pages/DishDetail";

//images below
import dummy from "./assets/dummy.jpeg";
import ack from "./assets/ack.jpg";
import vhn from "./assets/vhn.avif";
import tc1 from "./assets/tc1.avif";
import gj from "./assets/gj.avif";
import rm from "./assets/rm.avif";
import coke from "./assets/coke.avif";
import mc from "./assets/mc.avif";
import kp from "./assets/kp.avif";
import dm from "./assets/dm.avif";
import pbn from "./assets/pbn.avif";
import lfp from "./assets/lfp.avif";
import tc from "./assets/tc.avif";
import cb from "./assets/cb.avif";
import sp from "./assets/sp.jpeg";
import pl from "./assets/pl.jpeg";
import mmp from "./assets/mmp.jpeg";
import dd from "./assets/dd.jpeg";
import sc from "./assets/sc.jpeg";
import chilliC from "./assets/chilliC.jpeg";
import vb from "./assets/vb.jpeg";
import bc from "./assets/bc.jpeg";
import mk from "./assets/mk.jpeg";
import pbm from "./assets/pbm.jpeg";
import nvcc from "./assets/nvcc.jpeg";

function App() {
  const featuredProducts = [
    {
      id: 1,
      name: "Amritsari Chole Kulche",
      href: "#",
      imageSrc: ack,
      imageAlt: "Front of men's Basic Tee in black.",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Chilli Chicken (Gravy/Dry)",
      href: "#",
      imageSrc: chilliC,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$13.99/$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Paneer Lababdar",
      href: "#",
      imageSrc: pl,
      imageAlt: "Front of men's Basic Tee in black.",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Veg Hakka Noodles",
      href: "#",
      imageSrc: vhn,
      imageAlt: "Front of men's Basic Tee in black.",

      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const ChatCornerDishes = [
    {
      id: 1,
      name: "Chopati Chaat",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Samosa Chaat",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Tikki Chaat",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Dahi bhalla",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Samosa chana",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Tikki chana",
      href: "#",
      imageSrc: tc1,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Gol Gappe",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Dahi puri",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const desserts = [
    {
      id: 1,
      name: "Moong Daal Halwa",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Ras Malai (2 Pc)",
      href: "#",
      imageSrc: rm,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Gajar Halwa",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Gulab Jamun (2 Pc)",
      href: "#",
      imageSrc: gj,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Kulfi (Mango, Pista or Khoya)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const drinks = [
    {
      id: 1,
      name: "Special Milk Badam",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Masala Chai",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$2.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Mango Lassi",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Lassi (Sweet & Salty)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Pop",
      href: "#",
      imageSrc: coke,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$1.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Doodh Soda",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Gur wali Cha",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Indian Pop",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$2.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const indoChinese = [
    {
      id: 1,
      name: "Veg Manchurian (Gravy/Dry)",
      href: "#",
      imageSrc: mc,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$11.99/$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Chilli Paneer (Gravy/Dry)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99/$13.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Chilli Chicken (Gravy/Dry)",
      href: "#",
      imageSrc: chilliC,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$13.99/$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Spring Rolls (6 Pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Veg Hakka Noodles",
      href: "#",
      imageSrc: vhn,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$11.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },

    {
      id: 6,
      name: "Chicken Hakka Noodles",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const kidzMenu = [
    {
      id: 1,
      name: "Fries",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Poutine Butter Chicken",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Mozzarella Sticks (6 Pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Chicken Nuggets",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',2
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const lunchSpecial = [
    {
      id: 1,
      name: "Veg Noodle Burger/Combo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.99/$9.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Paneer Burger/Combo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$7.99/$10.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Paneer Tikka Wrap, Fries & Pop",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$11.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Chicken Tikka Wrap, Fries & Pop",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Butter Chicken Wrap, Fries & Pop",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Non Veg Curry Combo",
      href: "#",
      imageSrc: nvcc,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Veg Curry Combo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$13.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const mainCourseNonVeg = [
    {
      id: 1,
      name: "Butter Chicken",
      href: "#",
      imageSrc: bc,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Mango Chicken",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$7.19",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Chicken Curry Boneless",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$16.79",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Karahi Chicken",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Chicken Vindaloo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Chicken Korma",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Chicken Tikka Masala",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Goat Curry",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 9,
      name: "Lamb Curry",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 10,
      name: " Lamb Vindaloo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 11,
      name: "Lamb Rogan Josh",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const mainCourseVeg = [
    {
      id: 1,
      name: "Aloo Gobi",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Shahi Paneer",
      href: "#",
      imageSrc: sp,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$7.19",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Karahi Paneer",
      href: "#",
      imageSrc: kp,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$16.79",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Paneer Lababdar",
      href: "#",
      imageSrc: pl,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Palak Paneer",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Malai Kofta",
      href: "#",
      imageSrc: mk,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Paneer Tikka Masala",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Paneer Butter Masala",
      href: "#",
      imageSrc: pbm,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 9,
      name: "Mix Vegetable",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 10,
      name: "Methi Malai Paneer",
      href: "#",
      imageSrc: mmp,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 11,
      name: "Bhindi Masala",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 12,
      name: "Dal Makhani",
      href: "#",
      imageSrc: dm,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 13,
      name: "Yellow Dal Tarka",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 14,
      name: "Dal Dhaba",
      href: "#",
      imageSrc: dd,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 15,
      name: "Pindi Cholle ",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$14.39",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const naansAndBreads = [
    {
      id: 1,
      name: "Plain Butter Naan",
      href: "#",
      imageSrc: pbn,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$2.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Butter Naan (Layered)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Garlic Naan",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Stuffed Naan Aloo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Stuffed Naan Paneer",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Stuffef Naan Coconut",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Tandoori Roti",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$1.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Bhatura",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 9,
      name: "Lachha Parantha",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const nonvegAppetizers = [
    {
      id: 1,
      name: "Lahori Fish Pakora",
      href: "#",
      imageSrc: lfp,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$9.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Tandoori Chicken (4pc)",
      href: "#",
      imageSrc: tc,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Tandoori Wings",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Chicken Tikka/Malai Chicken Tikka",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.39',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Fish Tikka",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.39',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Chicken Kabab",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$15.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Lamb Kabab",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$16.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Steam/Fried Chicken Momo (10pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$10.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const pakwanSpecial = [
    {
      id: 1,
      name: "Amritsari Chole Kulche",
      href: "#",
      imageSrc: ack,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.39',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Tawa Kulcha",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$7.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Tawa Kulcha(Extra pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$1.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Bunn stuffed Kulche chole",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Amritsari Side Kulcha",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Channa Bowl",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const riceAndBiryani = [
    {
      id: 1,
      name: "Plain Rice",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$5.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Saffron Rice",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$7.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Jeera Rice",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$6.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Coconut Rice",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$7.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Veg Biryani",
      href: "#",
      imageSrc: vb,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Chicken Biryani",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Goat Biryani",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$15.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Lamb Biryani",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$15.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const sides = [
    {
      id: 1,
      name: "Plain Yogurt",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 2,
      name: "Raita",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$3.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 3,
      name: "Garden Salad",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 4,
      name: "Onion Green Chilli Salad",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$2.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 5,
      name: "Imli Onion",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$0.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 6,
      name: "Green Chutney (2 Oz)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$0.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 7,
      name: "Green Chutney (4 Oz)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$0.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 8,
      name: "Red Chutney (2 Oz)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$0.49',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 9,
      name: "Red Chutney (4 Oz)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$0.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
    {
      id: 10,
      name: "Channa (12 Oz)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "",
    },
  ];

  const vegAppetizers = [
    {
      id: 1,
      name: "Veg Samosa (2pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$4.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/fcdec148-b0e1-4d2e-a538-8bd76cbc803b",
    },
    {
      id: 2,
      name: "Aloo Tikki (3pc)",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: "$4.99",
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/68510acc-0a2b-40bd-8ddf-7dbea31b3b1c",
    },
    {
      id: 3,
      name: "Mix Veg Pakora",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$7.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/2083e464-0a04-43ea-8e4a-1247c5a9ba35",
    },
    {
      id: 4,
      name: "Paneer Pakora",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$9.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/acc74c6d-e59f-4c08-a552-a6ec04493a25",
    },
    {
      id: 5,
      name: "Chole Bhature",
      href: "#",
      imageSrc: cb,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$9.99',

      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/78214ef0-e976-4df7-8667-70c010df13a4",
    },
    {
      id: 6,
      name: "Soya Chaap/ Malai Soya",
      href: "#",
      imageSrc: sc,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$12.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/6e178862-7c95-41f1-87c9-5b86b94bbaa4",
    },
    {
      id: 7,
      name: "Paneer Tikka",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$14.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/cdfad2f7-c4cc-48c3-b224-6ff621a99605",
    },
    {
      id: 8,
      name: "Steam/Fried Veg Momo",
      href: "#",
      imageSrc: dummy,
      imageAlt: "Front of men's Basic Tee in black.",
      // price: '$8.99',
      doordash:
        "https://www.doordash.com/store/pakwan-indian-cuisine-edmonton-23169346/?event_type=autocomplete&pickup=false",
      uber: "https://www.ubereats.com/ca/store/pakwan-indian-cuisine/qeJwLh56WO-V3m63CcpD7w/db5aaae9-b16e-47bc-ada8-b78e0df5dfbe/423eda6e-9d87-4018-8c25-de0fad60ce90/8c65c316-8e07-49bb-84f1-c31de93bdfc3",
    },
  ];

  return (
    <div className="bg-white">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home dishes={featuredProducts} />} />
          <Route path="/menu" element={<FoodMenu />} />
          {/* <Route path="/gallery" element={< Gallery />} /> */}
          <Route path="/aboutUs" element={<About />} />
          <Route
            path="/dishes/vegAppetizers"
            element={<VegAppetizers products={vegAppetizers} />}
          />
          <Route
            path="/dishes/nonvegAppetizers"
            element={<NonVegAppetizers products={nonvegAppetizers} />}
          />
          <Route
            path="/dishes/chatCorner"
            element={<ChatCorner products={ChatCornerDishes} />}
          />
          <Route
            path="/dishes/mainCourseVeg"
            element={<MainCourseVeg products={mainCourseVeg} />}
          />
          <Route
            path="/dishes/mainCourseNonVeg"
            element={<MainCourseNonVeg products={mainCourseNonVeg} />}
          />
          <Route
            path="/dishes/pakwanSpecial"
            element={<PakwanSpecial products={pakwanSpecial} />}
          />
          <Route
            path="/dishes/lunchSpecial"
            element={<LunchSpecial products={lunchSpecial} />}
          />
          <Route
            path="/dishes/riceAndBiryani"
            element={<RiceAndBiryani products={riceAndBiryani} />}
          />
          <Route
            path="/dishes/indoChinese"
            element={<IndoChinese products={indoChinese} />}
          />
          <Route
            path="/dishes/naansAndBreads"
            element={<NaansAndBreads products={naansAndBreads} />}
          />
          <Route path="/sides" element={<Sides products={sides} />} />
          <Route path="/desserts" element={<Dessert products={desserts} />} />
          <Route path="/drinks" element={<Drinks products={drinks} />} />
          <Route path="/kidzMenu" element={<KidzMenu products={kidzMenu} />} />
          <Route
            path="/dishes/vegAppetizers/dishDetail/:id"
            element={<DishDetail dishes={vegAppetizers} />}
          />
          <Route
            path="/dishes/nonvegAppetizers/dishDetail/:id"
            element={<DishDetail dishes={nonvegAppetizers} />}
          />
          <Route
            path="/dishes/chatCorner/dishDetail/:id"
            element={<DishDetail dishes={ChatCornerDishes} />}
          />
          <Route
            path="/dishes/mainCourseVeg/dishDetail/:id"
            element={<DishDetail dishes={mainCourseVeg} />}
          />
          <Route
            path="/dishes/mainCourseNonVeg/dishDetail/:id"
            element={<DishDetail dishes={mainCourseNonVeg} />}
          />
          <Route
            path="/dishes/pakwanSpecial/dishDetail/:id"
            element={<DishDetail dishes={pakwanSpecial} />}
          />
          <Route
            path="/dishes/lunchSpecial/dishDetail/:id"
            element={<DishDetail dishes={lunchSpecial} />}
          />
          <Route
            path="/dishes/riceAndBiryani/dishDetail/:id"
            element={<DishDetail dishes={riceAndBiryani} />}
          />
          <Route
            path="/dishes/indoChinese/dishDetail/:id"
            element={<DishDetail dishes={indoChinese} />}
          />
          <Route
            path="/dishes/naansAndBreads/dishDetail/:id"
            element={<DishDetail dishes={naansAndBreads} />}
          />
          <Route
            path="/dishes/sides/dishDetail/:id"
            element={<DishDetail dishes={sides} />}
          />
          <Route
            path="/dishes/desserts/dishDetail/:id"
            element={<DishDetail dishes={desserts} />}
          />
          <Route
            path="/dishes/drinks/dishDetail/:id"
            element={<DishDetail dishes={drinks} />}
          />
          <Route
            path="/dishes/kidzMenu/dishDetail/:id"
            element={<DishDetail dishes={kidzMenu} />}
          />
          <Route
            path="/dishDetail/:id"
            element={<DishDetail dishes={featuredProducts} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
