import React from "react";
import Layout from "../layout/Layout";
import pakwan from "../assets/Pakwan.png";
import pakwan1 from "../assets/Pakwan1.JPG";

const AboutUs = () => {
  return (
    <Layout>
      <div className="w-full bg-white text-black py-16">
        <div className="container mx-auto text-center p-5">
          <p className="mb-8 text-opacity-80 lg:w-[90%]">
            Welcome to Pakwan, where we take pride in serving delicious and
            authentic South Asian cuisine. Our commitment is to provide a
            memorable dining experience for our guests. With two convenient
            locations in Edmonton, we bring the flavors of South Asia to your
            doorstep. Explore our diverse menu, carefully crafted by our
            experienced chefs, offering a delightful blend of spices and
            traditional recipes.
          </p>
          <h3 className="mb-8 text-2xl lg:text-4xl font-bold fontStyling heading-styling opacity-80">
            Our Locations
          </h3>
          <div className="lg:flex w-full">
            {/* Location 1 */}
            <div className="mb-10 lg:w-1/2 p-4">
              <a
                href="https://www.google.com/maps/place/Mill+Woods+Rd+NW,+Edmonton,+AB+T6K+4A9/@53.4592747,-113.4506282,17z/data=!3m1!4b1!4m6!3m5!1s0x53a0192de105cc03:0x8535c03a32ec8fea!8m2!3d53.4592747!4d-113.4480479!16s%2Fm%2F06w59ym?entry=ttu"
                className="block overflow-hidden rounded-md shadow-md"
              >
                <img
                  src={pakwan}
                  alt="Location 1"
                  className="w-full h-auto object-cover rounded-md consistent-image-style"
                />
              </a>
              <p
                className="mt-2 text-sm text-opacity-80"
                style={{ textAlign: "left" }}
              >
                <p> Address: 2809 Mill Woods Rd NW, Edmonton, AB T6K 4A9</p>
                <p style={{ textAlign: "left" }}>Phone: +1(587) 754-9388</p>
              </p>
            </div>
            {/* Location 2 */}
            <div className="lg:w-1/2 p-4">
              <a
                href="https://www.google.com/maps/place/Kerala+South+Indian+Restaurant/@53.4647967,-113.474262,17z/data=!3m1!4b1!4m6!3m5!1s0x53a01fb4f6f6440d:0x1d65e3a5f6f6852!8m2!3d53.4647967!4d-113.4716871!16s%2Fg%2F11j4mgsjvd?entry=ttu"
                className="block overflow-hidden rounded-md shadow-md"
              >
                <img
                  src={pakwan1}
                  alt="Location 2"
                  className="w-full h-auto object-cover rounded-md consistent-image-style"
                />
              </a>
              <p style={{ textAlign: "left" }}>
                <b>Address</b>: 9221 34 Ave NW, Edmonton, AB T6E 5T5
              </p>
              <p style={{ textAlign: "left" }}>Phone: +1(780) 244-4943</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
