import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Navigation = ({ navBackgroundColor }) => {
  const [showMobileView, setShowMobileView] = useState(false);
  const [changeNavColor, setChangeNavColor] = useState("transparent");
  const [changeTxtColor, setChangeTxtColor] = useState("black");
  const [scrollConstant, setScrollConstant] = useState();

  const handleNav = () => {
    setShowMobileView(prevValue => !prevValue);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 30) {
        setScrollConstant(70);
        setChangeNavColor("rgba(255,255,255, 1)");
        setChangeTxtColor("black");
      } else {
        setChangeNavColor(navBackgroundColor);
        setChangeTxtColor("black");
        setScrollConstant(0);
      }
      if (window.scrollY === 0) {
        setScrollConstant(0);
        setChangeNavColor("transparent");
        setChangeTxtColor("black");
      }
    });
  }, [navBackgroundColor]);

  return (
    <div
      className={
        scrollConstant === 70
          ? "fixed w-full m-auto block z-10 ease-in duration-300 navigationStyling top-0"
          : "fixed w-full m-auto block z-10 ease-in duration-300  navigationStyling"
      }
      style={{ backgroundColor: `${changeNavColor}` }}
    >
      <div className="max-w-[98%] m-auto flex justify-center items-center p-3 ">
        <ul
          className="hidden sm:flex 
        "
          style={{ color: `${changeTxtColor}` }}
        >
          <li className="py-3 px-10 hover:text-[#ff9760] fontStyling text-xl">
            <Link to="/">Home</Link>
          </li>
          <li className="py-3 px-10 hover:text-[#ff9760] fontStyling text-xl ">
            <Link to="/dishes/vegAppetizers">Food Menu</Link>
          </li>
          {/* <li className="py-3 px-10 hover:text-[#ff9760] fontStyling text-xl">
            <Link to="/gallery">Gallery</Link>
          </li> */}
          <li className="py-3 px-10 hover:text-[#ff9760] fontStyling text-xl">
            <Link to="/aboutUs">About us</Link>
          </li>
          {/* <li className="py-3 px-10 hover:text-[#ff9760] fontStyling text-xl">
            <Link to="/contactUs">Contact us</Link>
          </li> */}
        </ul>

        {/* Mobile Button */}
        <div className="block sm:hidden z-10">
          {showMobileView ? (
            <AiOutlineClose
              size={20}
              onClick={handleNav}
              style={{ color: `black` }}
            />
          ) : (
            <AiOutlineMenu
              size={20}
              onClick={handleNav}
              style={{ color: `${changeTxtColor}` }}
            />
          )}
        </div>
        {/* Mobile Menu */}
        <div
          className={
            showMobileView
              ? "sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black opacity-90 text-center ease-in duration-300 text-white"
              : "sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
          }
        >
          <ul>
            <li className="p-4 text-4xl hover:text-[#ff9760] fontStyling">
              <Link to="/">Home</Link>
            </li>
            <li className="p-4 text-4xl hover:text-[#ff9760] fontStyling">
              <Link to="/dishes/vegAppetizers">Food Menu</Link>
            </li>
            {/* <li className="p-4 text-4xl hover:text-[#ff9760] fontStyling">
              <Link to="/gallery">Gallery</Link>
            </li> */}
            <li className="p-4 text-4xl hover:text-[#ff9760] fontStyling">
              <Link to="/aboutUs">About us</Link>
            </li>
            {/* <li className="p-4 text-4xl hover:text-[#ff9760] fontStyling">
              <Link to="/contactUs">Contact us</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
