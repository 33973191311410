import React from "react";
import Layout from "../layout/Layout";
import MenuItem from "../components/MenuItem";

const FoodMenu = () => {
  return (
    <Layout>
      <div className="text-black mt-[100px] ml-[20px] fontStyling">
        <MenuItem />
      </div>
    </Layout>
  );
};

export default FoodMenu;
