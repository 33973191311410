import React from "react";

const LandingImage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-center bg-cover bg-no-repeat customImg">
      {/* If you have any content here that needs to be adjusted for a white background, add it here */}
    </div>
  );
};

export default LandingImage;
