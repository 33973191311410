import React from "react";
import LandingImage from "../components/LandingImage";
import Layout from "../layout/Layout";
import { FeaturedProducts } from "../components/FeaturedProducts";
import Testimonials from "../components/Testimonials";
import { Link } from "react-router-dom";

const Home = ({ dishes }) => {
  return (
    <Layout>
      <LandingImage />
      <FeaturedProducts dishes={dishes} />
      <Link to="https://www.google.com/search?client=firefox-b-d&q=pakwan+indian+cuisine+edmonton#lrd=0x53a019802aa2e155:0x9e9689144e8587f3,1,,,,">
        <Testimonials />
      </Link>
    </Layout>
  );
};

export default Home;
