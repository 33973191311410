import React from 'react'
// import Heading from '../../src/components/Heading'
import Navigation from '../../src/components/Navigation'
import Footer from '../../src/components/Footer'

const Layout = ({ children }) => {
    return (
        <>
            {/* <Heading /> */}
            <Navigation navBackgroundColor="transparent" />
            {children}
            <Footer />
        </>
    )
}

export default Layout